export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type Attestation = {
  __typename?: 'Attestation';
  solutions: Array<Solution>;
};

export type BaseOrder = {
  __typename?: 'BaseOrder';
  /** system identifier */
  _id: Scalars['String']['output'];
  accumulatedOptionsInfo: OptionInfo;
  accumulatedSlotInfo: SlotInfo;
  accumulatedSlotUsage: SlotUsage;
  authority?: Maybe<Scalars['String']['output']>;
  awaitingPayment: Scalars['Boolean']['output'];
  consumer: Scalars['String']['output'];
  depositSpent?: Maybe<Scalars['String']['output']>;
  /** blockchain id */
  id: Scalars['String']['output'];
  offerInfo?: Maybe<OfferInfo>;
  offerType: TOfferType;
  orderDeposit?: Maybe<Scalars['String']['output']>;
  orderInfo: OrderInfo;
  orderOutputReserve?: Maybe<Scalars['String']['output']>;
  orderResult: OrderResult;
  origins?: Maybe<Origins>;
  selectedUsage?: Maybe<OrderUsage>;
  startDate: Scalars['Float']['output'];
  teeOfferInfo?: Maybe<TeeOfferInfo>;
  totalDeposit?: Maybe<Scalars['String']['output']>;
  totalDepositSpent?: Maybe<Scalars['String']['output']>;
  totalDepositUnspent?: Maybe<Scalars['String']['output']>;
};

export type BaseOrderInputType = {
  accumulatedOptionsInfo: OptionInfoInput;
  accumulatedSlotInfo: SlotInfoInput;
  accumulatedSlotUsage: SlotUsageInput;
  awaitingPayment: Scalars['Boolean']['input'];
  consumer: Scalars['String']['input'];
  depositSpent?: InputMaybe<Scalars['String']['input']>;
  offerInfo?: InputMaybe<OfferInfoInput>;
  offerType: TOfferType;
  orderDeposit?: InputMaybe<Scalars['String']['input']>;
  orderInfo: OrderInfoInput;
  orderOutputReserve?: InputMaybe<Scalars['String']['input']>;
  orderResult: OrderResultInput;
  selectedUsage?: InputMaybe<OrderUsageInput>;
  startDate: Scalars['Float']['input'];
  teeOfferInfo?: InputMaybe<TeeOfferInfoInput>;
  totalDeposit?: InputMaybe<Scalars['String']['input']>;
  totalDepositSpent?: InputMaybe<Scalars['String']['input']>;
  totalDepositUnspent?: InputMaybe<Scalars['String']['input']>;
};

export type Config = {
  __typename?: 'Config';
  /** system identifier */
  _id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value: ValueObject;
};

export type ConfigConnection = {
  __typename?: 'ConfigConnection';
  edges?: Maybe<Array<ConfigEdge>>;
  pageInfo?: Maybe<ConfigPageInfo>;
};

export type ConfigEdge = {
  __typename?: 'ConfigEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Config>;
};

export type ConfigFilter = {
  /** filter by config name */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ConfigInputType = {
  /** system identifier */
  _id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  value: ValueObjectType;
};

export type ConfigPageInfo = {
  __typename?: 'ConfigPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type ConnectionArgs = {
  /** Paginate after opaque cursor */
  after?: InputMaybe<Scalars['String']['input']>;
  /** Paginate before opaque cursor */
  before?: InputMaybe<Scalars['String']['input']>;
  /** Paginate first */
  first?: InputMaybe<Scalars['Float']['input']>;
  /** Paginate last */
  last?: InputMaybe<Scalars['Float']['input']>;
  /** sort */
  sort?: InputMaybe<Array<SortParam>>;
  /** sort field name (deprecated, use "sort" field) */
  sortBy?: InputMaybe<Scalars['String']['input']>;
  /** sort directory - ASC or DESC. Default value ASC (deprecated, use "sort" field) */
  sortDir?: InputMaybe<Scalars['String']['input']>;
};

export type CoresStatisticPoint = {
  __typename?: 'CoresStatisticPoint';
  timestamp: Scalars['Float']['output'];
  totalCpuCores: Scalars['Float']['output'];
  totalGpuCores: Scalars['Float']['output'];
  useCpuCores: Scalars['Float']['output'];
  useGpuCores: Scalars['Float']['output'];
};

export type Erc20 = {
  __typename?: 'Erc20';
  /** system identifier */
  _id: Scalars['String']['output'];
  balance?: Maybe<Scalars['String']['output']>;
  netBalance: Scalars['String']['output'];
  /** owner address */
  owner: Scalars['String']['output'];
  providerName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Erc20Connection = {
  __typename?: 'Erc20Connection';
  edges?: Maybe<Array<Erc20Edge>>;
  pageInfo?: Maybe<Erc20PageInfo>;
};

export type Erc20Edge = {
  __typename?: 'Erc20Edge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Erc20>;
};

export type Erc20InputType = {
  /** system identifier */
  _id: Scalars['String']['input'];
  balance?: InputMaybe<Scalars['String']['input']>;
  netBalance: Scalars['String']['input'];
  /** owner address */
  owner: Scalars['String']['input'];
  providerName?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Erc20PageInfo = {
  __typename?: 'Erc20PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Erc20rFilter = {
  /** filter by owner address */
  owner?: InputMaybe<Scalars['String']['input']>;
};

export type Event = {
  __typename?: 'Event';
  _id: Scalars['String']['output'];
  change?: Maybe<Scalars['String']['output']>;
  consumer?: Maybe<Scalars['String']['output']>;
  contract: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deposit?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offerId?: Maybe<Scalars['String']['output']>;
  orderId: Scalars['String']['output'];
  orderStatus?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  parentOrderId?: Maybe<Scalars['String']['output']>;
  profit?: Maybe<Scalars['String']['output']>;
  spender?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  transactionBlockNumber: Scalars['Float']['output'];
  transactionHash: Scalars['String']['output'];
  transactionTimestamp: Scalars['Float']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type EventConnection = {
  __typename?: 'EventConnection';
  edges?: Maybe<Array<EventEdge>>;
  pageInfo?: Maybe<EventPageInfo>;
};

export type EventDataFilter = {
  /** retrieve events saved in the database on or after the specified date (createdAtFrom) */
  createdAtFrom?: InputMaybe<Scalars['DateTime']['input']>;
  /** retrieve events saved in the database on or before the specified date (createdAtTo) */
  createdAtTo?: InputMaybe<Scalars['DateTime']['input']>;
  /** filter by event name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** filter by order ID */
  orderId?: InputMaybe<Scalars['String']['input']>;
  /** filter by order IDs */
  orderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter by parent order ID */
  parentOrderId?: InputMaybe<Scalars['String']['input']>;
};

export type EventDataInput = {
  _id: Scalars['String']['input'];
  change?: InputMaybe<Scalars['String']['input']>;
  consumer?: InputMaybe<Scalars['String']['input']>;
  contract: Scalars['String']['input'];
  deposit?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  offerId?: InputMaybe<Scalars['String']['input']>;
  orderId: Scalars['String']['input'];
  orderStatus?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  parentOrderId?: InputMaybe<Scalars['String']['input']>;
  profit?: InputMaybe<Scalars['String']['input']>;
  spender?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  transactionBlockNumber: Scalars['Float']['input'];
  transactionHash: Scalars['String']['input'];
  transactionTimestamp: Scalars['Float']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type EventEdge = {
  __typename?: 'EventEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Event>;
};

export type EventFilter = {
  /** filter events by custom params */
  events?: InputMaybe<Array<EventSource>>;
};

export type EventFilterField = {
  /** filter events by consumer */
  consumer?: InputMaybe<Scalars['String']['input']>;
  /** filter by offerType */
  offerType?: InputMaybe<TOfferType>;
};

export type EventPageInfo = {
  __typename?: 'EventPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type EventSource = {
  /** filter */
  filter?: InputMaybe<EventFilterField>;
  /** subscribe on this events by source */
  source?: InputMaybe<SubscriptionSource>;
};

export type HardwareInfo = {
  __typename?: 'HardwareInfo';
  optionInfo: OptionInfo;
  slotInfo: SlotInfo;
};

export type HardwareInfoInput = {
  optionInfo: OptionInfoInput;
  slotInfo: SlotInfoInput;
};

export type ListConfigResponse = {
  __typename?: 'ListConfigResponse';
  page: ConfigConnection;
  pageData?: Maybe<PageDataDto>;
};

export type ListErc20Response = {
  __typename?: 'ListErc20Response';
  page: Erc20Connection;
  pageData?: Maybe<PageDataDto>;
};

export type ListEventResponse = {
  __typename?: 'ListEventResponse';
  page: EventConnection;
  pageData?: Maybe<PageDataDto>;
};

export type ListOffersResponse = {
  __typename?: 'ListOffersResponse';
  page: OfferConnection;
  pageData?: Maybe<PageDataDto>;
};

export type ListOrdersResponse = {
  __typename?: 'ListOrdersResponse';
  page: OrderConnection;
  pageData?: Maybe<PageDataDto>;
};

export type ListProvidersResponse = {
  __typename?: 'ListProvidersResponse';
  page: ProviderConnection;
  pageData?: Maybe<PageDataDto>;
};

export type ListTeeOffersAndSlots = {
  __typename?: 'ListTeeOffersAndSlots';
  page: TeeOffersAndSLotsConnection;
  pageData?: Maybe<PageDataDto>;
};

export type ListTeeOffersResponse = {
  __typename?: 'ListTeeOffersResponse';
  page: TeeOfferConnection;
  pageData?: Maybe<PageDataDto>;
};

export type MatchingOptionResult = {
  __typename?: 'MatchingOptionResult';
  count: Scalars['Float']['output'];
  id: Scalars['String']['output'];
  info: OptionInfo;
  usage: SlotUsage;
};

export type MatchingOptions = {
  __typename?: 'MatchingOptions';
  cumulativeValues: OptionInfo;
  optionResults: Array<MatchingOptionResult>;
  priceFixed: Scalars['String']['output'];
  pricePerHour: Scalars['String']['output'];
};

export type MatchingOptionsType = {
  cumulativeValues: OptionInfoInput;
  optionResults: Array<MatchingTeeOfferOptionInput>;
  priceFixed: Scalars['String']['input'];
  pricePerHour: Scalars['String']['input'];
};

export type MatchingSlot = {
  __typename?: 'MatchingSlot';
  multiplier: Scalars['Float']['output'];
  price: Scalars['String']['output'];
  slot: TeeOfferSlot;
};

export type MatchingSlotType = {
  multiplier: Scalars['Float']['input'];
  price: Scalars['String']['input'];
  slot: TeeOfferSlotInput;
};

export type MatchingTeeOfferOptionInput = {
  count: Scalars['Float']['input'];
  id: Scalars['String']['input'];
  info: OptionInfoInput;
  usage: SlotUsageInput;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Transfers custom amount of coins to specific address */
  customTransfer: Scalars['Boolean']['output'];
  /** Transfers custom amount of TEE tokens to specific address */
  teeCustomTransfer: Scalars['Boolean']['output'];
  /** Transfers specific amount of TEE tokens to specific address */
  teeTransfer: Scalars['Boolean']['output'];
  /** Transfers specific amount of coins to specific address */
  transfer: Scalars['Boolean']['output'];
};


export type MutationCustomTransferArgs = {
  amount?: InputMaybe<Scalars['String']['input']>;
  destinationAddress?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTeeCustomTransferArgs = {
  amount?: InputMaybe<Scalars['String']['input']>;
  destinationAddress?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTeeTransferArgs = {
  destinationAddress?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTransferArgs = {
  destinationAddress?: InputMaybe<Scalars['String']['input']>;
};

export type Offer = {
  __typename?: 'Offer';
  /** system identifier */
  _id: Scalars['String']['output'];
  authority?: Maybe<Scalars['String']['output']>;
  disabledAfter: Scalars['Float']['output'];
  enabled: Scalars['Boolean']['output'];
  /** blockchain id */
  id: Scalars['String']['output'];
  inactive?: Maybe<Scalars['Boolean']['output']>;
  offerInfo: OfferInfo;
  origins?: Maybe<Origins>;
  providerInfo: ProviderInformation;
  slots: Array<OfferSlot>;
  stats?: Maybe<OfferStats>;
};

export type OfferConfiguration = {
  __typename?: 'OfferConfiguration';
  bandwidth: Scalars['Float']['output'];
  cpuCores: Scalars['Float']['output'];
  diskUsage: Scalars['Float']['output'];
  externalPort: Scalars['Float']['output'];
  gpuCores: Scalars['Float']['output'];
  maxTimeMinutes: Scalars['Float']['output'];
  minTimeMinutes: Scalars['Float']['output'];
  priceFixed: Scalars['String']['output'];
  pricePerHour: Scalars['String']['output'];
  ram: Scalars['Float']['output'];
  /** @deprecated use minTimeMinutes/maxTimeMinutes instead */
  time: Array<Scalars['Float']['output']>;
  traffic: Scalars['Float']['output'];
  vram: Scalars['Float']['output'];
};

export type OfferConnection = {
  __typename?: 'OfferConnection';
  edges?: Maybe<Array<OfferEdge>>;
  pageInfo?: Maybe<OfferPageInfo>;
};

export type OfferEdge = {
  __typename?: 'OfferEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Offer>;
};

export type OfferFilter = {
  /** filter by enabled, "false" by default */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** exclude offers with selected ids */
  excludeIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** exclude filter by offerInfo -> restrictions -> type */
  excludeOfferRestrictionType?: InputMaybe<Array<TOfferType>>;
  /** filter by offerInfo → group */
  group?: InputMaybe<Scalars['String']['input']>;
  /** filter by blockchain id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** filter by offer ids */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter by inactive, "false" by default */
  inactive?: InputMaybe<Scalars['Boolean']['input']>;
  /** include filter by offerInfo -> restrictions -> type */
  includeOfferRestrictionType?: InputMaybe<Array<TOfferType>>;
  /** filter by offerInfo → name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** filter by offerInfo -> type */
  offerType?: InputMaybe<TOfferType>;
  /** filter by providerInfo → actionAccount */
  providerActionAccounts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter by offerInfo -> restrictions -> offers */
  restrictions?: InputMaybe<Array<Scalars['String']['input']>>;
  /** get only compatible with selected offers */
  selectedOfferIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OfferInfo = {
  __typename?: 'OfferInfo';
  allowedAccounts: Array<Scalars['String']['output']>;
  allowedArgs?: Maybe<Scalars['String']['output']>;
  argsPublicKey: Scalars['String']['output'];
  cancelable: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  /**
   * The supported offers group.
   *
   *      0 - Input,
   *
   *      1 - Output
   *
   */
  group: Scalars['String']['output'];
  hash: Scalars['String']['output'];
  input: Scalars['String']['output'];
  linkage: Scalars['String']['output'];
  metadata: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /**
   * The supported offers type.
   *
   *      TeeOffer = '0',
   *
   *      Storage = '1',
   *
   *      Solution = '2',
   *
   *      Data = '3'
   *
   */
  offerType: Scalars['String']['output'];
  output: Scalars['String']['output'];
  restrictions?: Maybe<OfferRestrictions>;
  resultResource: Scalars['String']['output'];
  signatureKey: Scalars['String']['output'];
};

export type OfferInfoInput = {
  allowedAccounts: Array<Scalars['String']['input']>;
  allowedArgs?: InputMaybe<Scalars['String']['input']>;
  argsPublicKey: Scalars['String']['input'];
  cancelable: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  /**
   * The supported offers group.
   *
   *      0 - Input,
   *
   *      1 - Output
   *
   */
  group: Scalars['String']['input'];
  hash: Scalars['String']['input'];
  input: Scalars['String']['input'];
  linkage: Scalars['String']['input'];
  metadata: Scalars['String']['input'];
  name: Scalars['String']['input'];
  /**
   * The supported offers type.
   *
   *      TeeOffer = '0',
   *
   *      Storage = '1',
   *
   *      Solution = '2',
   *
   *      Data = '3'
   *
   */
  offerType: Scalars['String']['input'];
  output: Scalars['String']['input'];
  restrictions?: InputMaybe<OfferRestrictionsInput>;
  resultResource: Scalars['String']['input'];
  signatureKey: Scalars['String']['input'];
};

export type OfferInputType = {
  disabledAfter: Scalars['Float']['input'];
  enabled: Scalars['Boolean']['input'];
  inactive?: InputMaybe<Scalars['Boolean']['input']>;
  offerInfo: OfferInfoInput;
  providerInfo: ProviderInformationInput;
  slots: Array<OfferSlotInput>;
  stats?: InputMaybe<OfferStatsInput>;
};

export type OfferPageInfo = {
  __typename?: 'OfferPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type OfferRestrictions = {
  __typename?: 'OfferRestrictions';
  offers?: Maybe<Array<Scalars['String']['output']>>;
  types?: Maybe<Array<Scalars['String']['output']>>;
};

export type OfferRestrictionsInput = {
  offers?: InputMaybe<Array<Scalars['String']['input']>>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OfferSlot = {
  __typename?: 'OfferSlot';
  id: Scalars['String']['output'];
  info: SlotInfo;
  option: OptionInfo;
  usage: SlotUsage;
};

export type OfferSlotInput = {
  id: Scalars['String']['input'];
  info: SlotInfoInput;
  option: OptionInfoInput;
  usage: SlotUsageInput;
};

export type OfferSlotPair = {
  __typename?: 'OfferSlotPair';
  offerId: Scalars['String']['output'];
  slotId: Scalars['String']['output'];
};

export type OfferStats = {
  __typename?: 'OfferStats';
  blocked?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  canceling?: Maybe<Scalars['Float']['output']>;
  done?: Maybe<Scalars['Float']['output']>;
  error?: Maybe<Scalars['Float']['output']>;
  freeCores?: Maybe<Scalars['Float']['output']>;
  new?: Maybe<Scalars['Float']['output']>;
  ordersInQueue?: Maybe<Scalars['Float']['output']>;
  processing?: Maybe<Scalars['Float']['output']>;
  suspended?: Maybe<Scalars['Float']['output']>;
};

export type OfferStatsInput = {
  blocked?: InputMaybe<Scalars['Float']['input']>;
  canceled?: InputMaybe<Scalars['Float']['input']>;
  canceling?: InputMaybe<Scalars['Float']['input']>;
  done?: InputMaybe<Scalars['Float']['input']>;
  error?: InputMaybe<Scalars['Float']['input']>;
  freeCores?: InputMaybe<Scalars['Float']['input']>;
  new?: InputMaybe<Scalars['Float']['input']>;
  ordersInQueue?: InputMaybe<Scalars['Float']['input']>;
  processing?: InputMaybe<Scalars['Float']['input']>;
  suspended?: InputMaybe<Scalars['Float']['input']>;
};

/** The supported offers type. */
export enum OfferType {
  Data = 'Data',
  Solution = 'Solution',
  Storage = 'Storage',
  TeeOffer = 'TeeOffer'
}

export type OptionInfo = {
  __typename?: 'OptionInfo';
  bandwidth: Scalars['Float']['output'];
  externalPort: Scalars['Float']['output'];
  traffic: Scalars['Float']['output'];
};

export type OptionInfoInput = {
  bandwidth: Scalars['Float']['input'];
  externalPort: Scalars['Float']['input'];
  traffic: Scalars['Float']['input'];
};

export type Order = {
  __typename?: 'Order';
  /** system identifier */
  _id: Scalars['String']['output'];
  accumulatedOptionsInfo: OptionInfo;
  accumulatedSlotInfo: SlotInfo;
  accumulatedSlotUsage: SlotUsage;
  authority?: Maybe<Scalars['String']['output']>;
  awaitingPayment: Scalars['Boolean']['output'];
  consumer: Scalars['String']['output'];
  depositSpent?: Maybe<Scalars['String']['output']>;
  /** blockchain id */
  id: Scalars['String']['output'];
  offerInfo?: Maybe<OfferInfo>;
  offerType: TOfferType;
  orderDeposit?: Maybe<Scalars['String']['output']>;
  orderInfo: OrderInfo;
  orderOutputReserve?: Maybe<Scalars['String']['output']>;
  orderResult: OrderResult;
  origins?: Maybe<Origins>;
  parentOrder?: Maybe<ParentOrder>;
  providerInfo: ProviderInformation;
  selectedUsage?: Maybe<OrderUsage>;
  startDate: Scalars['Float']['output'];
  subOrders?: Maybe<Array<BaseOrder>>;
  teeOfferInfo?: Maybe<TeeOfferInfo>;
  totalDeposit?: Maybe<Scalars['String']['output']>;
  totalDepositSpent?: Maybe<Scalars['String']['output']>;
  totalDepositUnspent?: Maybe<Scalars['String']['output']>;
};

export type OrderArgs = {
  __typename?: 'OrderArgs';
  inputOffersIds: Array<Scalars['String']['output']>;
  inputOffersVersions: Array<Scalars['Float']['output']>;
  outputOfferId: Scalars['String']['output'];
  outputOfferVersion: Scalars['Float']['output'];
};

export type OrderArgsInput = {
  inputOffersIds: Array<Scalars['String']['input']>;
  inputOffersVersions: Array<Scalars['Float']['input']>;
  outputOfferId: Scalars['String']['input'];
  outputOfferVersion: Scalars['Float']['input'];
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges?: Maybe<Array<OrderEdge>>;
  pageInfo?: Maybe<OrderPageInfo>;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Order>;
};

export enum OrderEventType {
  OrderCreated = 'orderCreated',
  OrderUpdated = 'orderUpdated',
  SuborderCreated = 'suborderCreated',
  SuborderUpdated = 'suborderUpdated'
}

export type OrderEventsUpdated = {
  __typename?: 'OrderEventsUpdated';
  _id: Scalars['String']['output'];
  change?: Maybe<Scalars['String']['output']>;
  consumer?: Maybe<Scalars['String']['output']>;
  contract: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deposit?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offerId?: Maybe<Scalars['String']['output']>;
  orderId: Scalars['String']['output'];
  orderStatus?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  parentOrderId?: Maybe<Scalars['String']['output']>;
  profit?: Maybe<Scalars['String']['output']>;
  spender?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  transactionBlockNumber: Scalars['Float']['output'];
  transactionHash: Scalars['String']['output'];
  transactionTimestamp: Scalars['Float']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type OrderEventsUpdatedInput = {
  /** filter by order ids */
  orderIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter by parent order ids */
  parentOrderIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrderInfo = {
  __typename?: 'OrderInfo';
  args: OrderArgs;
  encryptedArgs: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  offerId: Scalars['String']['output'];
  offerVersion: Scalars['Float']['output'];
  resultInfo: OrderResultInfo;
  /**
   * description of values:
   * 
   *     New = '0',
   *
   *     Processing = '1',
   *
   *     Canceling = '2',
   *
   *     Canceled = '3',
   *
   *     Done = '4',
   *
   *     Error = '5',
   *
   *     Blocked = '6',
   *
   *     Suspended = '7',
   *
   *     AwaitingPayment = '8'
   *
   *
   */
  status: Scalars['String']['output'];
};

export type OrderInfoInput = {
  args: OrderArgsInput;
  encryptedArgs: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  offerId: Scalars['String']['input'];
  offerVersion: Scalars['Float']['input'];
  resultInfo: OrderResultInfoInput;
  /**
   * description of values:
   * 
   *     New = '0',
   *
   *     Processing = '1',
   *
   *     Canceling = '2',
   *
   *     Canceled = '3',
   *
   *     Done = '4',
   *
   *     Error = '5',
   *
   *     Blocked = '6',
   *
   *     Suspended = '7',
   *
   *     AwaitingPayment = '8'
   *
   *
   */
  status: Scalars['String']['input'];
};

export type OrderInputType = {
  accumulatedOptionsInfo: OptionInfoInput;
  accumulatedSlotInfo: SlotInfoInput;
  accumulatedSlotUsage: SlotUsageInput;
  awaitingPayment: Scalars['Boolean']['input'];
  consumer: Scalars['String']['input'];
  depositSpent?: InputMaybe<Scalars['String']['input']>;
  offerInfo?: InputMaybe<OfferInfoInput>;
  offerType: TOfferType;
  orderDeposit?: InputMaybe<Scalars['String']['input']>;
  orderInfo: OrderInfoInput;
  orderOutputReserve?: InputMaybe<Scalars['String']['input']>;
  orderResult: OrderResultInput;
  parentOrder?: InputMaybe<ParentOrderInputType>;
  providerInfo: ProviderInformationInput;
  selectedUsage?: InputMaybe<OrderUsageInput>;
  startDate: Scalars['Float']['input'];
  subOrders?: InputMaybe<Array<BaseOrderInputType>>;
  teeOfferInfo?: InputMaybe<TeeOfferInfoInput>;
  totalDeposit?: InputMaybe<Scalars['String']['input']>;
  totalDepositSpent?: InputMaybe<Scalars['String']['input']>;
  totalDepositUnspent?: InputMaybe<Scalars['String']['input']>;
};

export type OrderObject = {
  __typename?: 'OrderObject';
  accumulatedOptionsInfo: OptionInfo;
  accumulatedSlotInfo: SlotInfo;
  accumulatedSlotUsage: SlotUsage;
  awaitingPayment?: Maybe<Scalars['Boolean']['output']>;
  consumer: Scalars['String']['output'];
  depositSpent?: Maybe<Scalars['String']['output']>;
  /** blockchain id */
  id: Scalars['String']['output'];
  offerInfo?: Maybe<OfferInfo>;
  offerType: Scalars['String']['output'];
  orderDeposit?: Maybe<Scalars['String']['output']>;
  /** blockchain id */
  orderId: Scalars['String']['output'];
  orderInfo: OrderInfo;
  orderOutputReserve?: Maybe<Scalars['String']['output']>;
  orderResult: OrderResult;
  origins?: Maybe<Origins>;
  parentOrder?: Maybe<Scalars['String']['output']>;
  selectedUsage: OrderUsage;
  startDate: Scalars['Float']['output'];
  subOrders: Array<Scalars['String']['output']>;
  teeOfferInfo?: Maybe<TeeOfferInfo>;
  totalDeposit?: Maybe<Scalars['String']['output']>;
  totalDepositSpent?: Maybe<Scalars['String']['output']>;
  totalDepositUnspent?: Maybe<Scalars['String']['output']>;
};

export type OrderPageInfo = {
  __typename?: 'OrderPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type OrderPayload = {
  __typename?: 'OrderPayload';
  eventType?: Maybe<OrderEventType>;
  order: OrderObject;
};

export type OrderResult = {
  __typename?: 'OrderResult';
  encryptedResult?: Maybe<Scalars['String']['output']>;
  orderPrice?: Maybe<Scalars['String']['output']>;
};

export type OrderResultInfo = {
  __typename?: 'OrderResultInfo';
  encryptedInfo: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
};

export type OrderResultInfoInput = {
  encryptedInfo: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
};

export type OrderResultInput = {
  encryptedResult?: InputMaybe<Scalars['String']['input']>;
  orderPrice?: InputMaybe<Scalars['String']['input']>;
};

export type OrderStatusUpdatedInput = {
  /** filter by order ids */
  orderIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type OrderStatusUpdatedPayload = {
  __typename?: 'OrderStatusUpdatedPayload';
  orderId: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type OrderUsage = {
  __typename?: 'OrderUsage';
  optionIds: Array<Scalars['String']['output']>;
  optionInfo: Array<OptionInfo>;
  optionUsage: Array<SlotUsage>;
  optionsCount: Array<Scalars['Float']['output']>;
  slotCount: Scalars['Float']['output'];
  slotInfo: SlotInfo;
  slotUsage: SlotUsage;
};

export type OrderUsageInput = {
  optionIds: Array<Scalars['String']['input']>;
  optionInfo: Array<OptionInfoInput>;
  optionUsage: Array<SlotUsageInput>;
  optionsCount: Array<Scalars['Float']['input']>;
  slotCount: Scalars['Float']['input'];
  slotInfo: SlotInfoInput;
  slotUsage: SlotUsageInput;
};

export type OrdersFilter = {
  /** filter by awaitingPayment */
  awaitingPayment?: InputMaybe<Scalars['Boolean']['input']>;
  /** filter by orderInfo -> consumer */
  consumer?: InputMaybe<Scalars['String']['input']>;
  /** exclude filter by orderInfo -> status */
  excludeStatuses?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter by blockchain id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** filter by blockchain ids */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** include filter by orderInfo -> status */
  includeStatuses?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter by orderInfo -> args -> inputOffers */
  inputOffers?: InputMaybe<Array<Scalars['String']['input']>>;
  /** This param will be removed in the next version. Use offerIds. */
  offerId?: InputMaybe<Scalars['String']['input']>;
  /** filter by orderInfo -> offerId */
  offerIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter by offerType */
  offerType?: InputMaybe<TOfferType>;
  /** filter by orderInfo -> args -> outputOffers */
  outputOffers?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter by parentOrder -> orderId */
  parentOrder?: InputMaybe<Scalars['String']['input']>;
  /** filter by orderInfo -> status */
  status?: InputMaybe<Scalars['String']['input']>;
};

export type Origins = {
  __typename?: 'Origins';
  createdBy: Scalars['String']['output'];
  createdDate: Scalars['Float']['output'];
  modifiedBy: Scalars['String']['output'];
  modifiedDate: Scalars['Float']['output'];
};

export type OriginsInput = {
  createdBy: Scalars['String']['input'];
  createdDate: Scalars['Float']['input'];
  modifiedBy: Scalars['String']['input'];
  modifiedDate: Scalars['Float']['input'];
};

export type PageDataDto = {
  __typename?: 'PageDataDto';
  /** total number of documents */
  count: Scalars['Float']['output'];
  /** selection limit */
  limit: Scalars['Float']['output'];
  /** selection offset */
  offset: Scalars['Float']['output'];
};

export type ParentOrder = {
  __typename?: 'ParentOrder';
  /** system identifier */
  _id: Scalars['String']['output'];
  accumulatedOptionsInfo: OptionInfo;
  accumulatedSlotInfo: SlotInfo;
  accumulatedSlotUsage: SlotUsage;
  authority?: Maybe<Scalars['String']['output']>;
  awaitingPayment: Scalars['Boolean']['output'];
  consumer: Scalars['String']['output'];
  depositSpent?: Maybe<Scalars['String']['output']>;
  /** blockchain id */
  id: Scalars['String']['output'];
  offerInfo?: Maybe<OfferInfo>;
  offerType: TOfferType;
  orderDeposit?: Maybe<Scalars['String']['output']>;
  orderInfo: OrderInfo;
  orderOutputReserve?: Maybe<Scalars['String']['output']>;
  orderResult: OrderResult;
  origins?: Maybe<Origins>;
  parentOrder?: Maybe<Scalars['String']['output']>;
  selectedUsage?: Maybe<OrderUsage>;
  startDate: Scalars['Float']['output'];
  teeOfferInfo?: Maybe<TeeOfferInfo>;
  totalDeposit?: Maybe<Scalars['String']['output']>;
  totalDepositSpent?: Maybe<Scalars['String']['output']>;
  totalDepositUnspent?: Maybe<Scalars['String']['output']>;
};

export type ParentOrderInputType = {
  accumulatedOptionsInfo: OptionInfoInput;
  accumulatedSlotInfo: SlotInfoInput;
  accumulatedSlotUsage: SlotUsageInput;
  awaitingPayment: Scalars['Boolean']['input'];
  consumer: Scalars['String']['input'];
  depositSpent?: InputMaybe<Scalars['String']['input']>;
  offerInfo?: InputMaybe<OfferInfoInput>;
  offerType: TOfferType;
  orderDeposit?: InputMaybe<Scalars['String']['input']>;
  orderInfo: OrderInfoInput;
  orderOutputReserve?: InputMaybe<Scalars['String']['input']>;
  orderResult: OrderResultInput;
  parentOrder?: InputMaybe<Scalars['String']['input']>;
  selectedUsage?: InputMaybe<OrderUsageInput>;
  startDate: Scalars['Float']['input'];
  teeOfferInfo?: InputMaybe<TeeOfferInfoInput>;
  totalDeposit?: InputMaybe<Scalars['String']['input']>;
  totalDepositSpent?: InputMaybe<Scalars['String']['input']>;
  totalDepositUnspent?: InputMaybe<Scalars['String']['input']>;
};

/** Slot price type. */
export enum PriceType {
  Fixed = 'Fixed',
  PerHour = 'PerHour'
}

export type Provider = {
  __typename?: 'Provider';
  /** system identifier */
  _id: Scalars['String']['output'];
  /** provider address */
  address: Scalars['String']['output'];
  authority?: Maybe<Scalars['String']['output']>;
  availableDeposit?: Maybe<Scalars['String']['output']>;
  origins?: Maybe<Origins>;
  providerInfo: ProviderInfo;
  teeOffers?: Maybe<Array<Scalars['String']['output']>>;
  valueOffers?: Maybe<Array<Scalars['String']['output']>>;
};

export type ProviderConnection = {
  __typename?: 'ProviderConnection';
  edges?: Maybe<Array<ProviderEdge>>;
  pageInfo?: Maybe<ProviderPageInfo>;
};

export type ProviderEdge = {
  __typename?: 'ProviderEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Provider>;
};

export type ProviderFilter = {
  /** filter by address */
  address?: InputMaybe<Scalars['String']['input']>;
};

export type ProviderInfo = {
  __typename?: 'ProviderInfo';
  actionAccount: Scalars['String']['output'];
  description: Scalars['String']['output'];
  metadata: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tokenReceiver: Scalars['String']['output'];
};

export type ProviderInfoInput = {
  actionAccount: Scalars['String']['input'];
  description: Scalars['String']['input'];
  metadata: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tokenReceiver: Scalars['String']['input'];
};

export type ProviderInformation = {
  __typename?: 'ProviderInformation';
  actionAccount: Scalars['String']['output'];
  description: Scalars['String']['output'];
  metadata: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tokenReceiver: Scalars['String']['output'];
};

export type ProviderInformationInput = {
  actionAccount: Scalars['String']['input'];
  description: Scalars['String']['input'];
  metadata: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tokenReceiver: Scalars['String']['input'];
};

export type ProviderInputType = {
  /** system identifier */
  _id: Scalars['String']['input'];
  /** provider address */
  address: Scalars['String']['input'];
  authority?: InputMaybe<Scalars['String']['input']>;
  availableDeposit?: InputMaybe<Scalars['String']['input']>;
  origins?: InputMaybe<OriginsInput>;
  providerInfo: ProviderInfoInput;
  teeOffers?: InputMaybe<Array<Scalars['String']['input']>>;
  valueOffers?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ProviderPageInfo = {
  __typename?: 'ProviderPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  attestation: Attestation;
  autoSelectValueSlots: Array<OfferSlotPair>;
  balanceOf: Scalars['String']['output'];
  checkAuthToken: Scalars['String']['output'];
  config: Config;
  configs: ListConfigResponse;
  coresStatistic: Array<CoresStatisticPoint>;
  erc20: Erc20;
  event: Event;
  events: ListEventResponse;
  getMatchingTeeSlots: ListTeeOffersAndSlots;
  getMinimalConfiguration: OfferConfiguration;
  listErc20: ListErc20Response;
  offer: Offer;
  offerType: OfferType;
  offers: ListOffersResponse;
  order: Order;
  orders: ListOrdersResponse;
  /** Average processing time in milliseconds */
  ordersProcessingStatistic: Array<StatisticPoint>;
  ordersStatusesStatistic: Array<StatisticPoint>;
  provider: Provider;
  providers: ListProvidersResponse;
  teeBalanceOf: Scalars['String']['output'];
  teeOffer: TeeOffer;
  teeOffers: ListTeeOffersResponse;
  uniqConsumersStatistic: Array<StatisticPoint>;
  validateConfiguraion: ValidationResult;
};


export type QueryAutoSelectValueSlotsArgs = {
  minTimeMinutes?: InputMaybe<Scalars['Int']['input']>;
  offerIds: Array<Scalars['String']['input']>;
};


export type QueryBalanceOfArgs = {
  address: Scalars['String']['input'];
};


export type QueryConfigArgs = {
  _id: Scalars['String']['input'];
};


export type QueryConfigsArgs = {
  filter?: InputMaybe<ConfigFilter>;
  pagination: ConnectionArgs;
};


export type QueryCoresStatisticArgs = {
  dateFrom: Scalars['Float']['input'];
  dateTo: Scalars['Float']['input'];
  pointsCount?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryErc20Args = {
  _id: Scalars['String']['input'];
};


export type QueryEventArgs = {
  _id: Scalars['String']['input'];
};


export type QueryEventsArgs = {
  filter?: InputMaybe<EventDataFilter>;
  pagination: ConnectionArgs;
};


export type QueryGetMatchingTeeSlotsArgs = {
  filter?: InputMaybe<TeeOfferFilter>;
  pagination: ConnectionArgs;
};


export type QueryGetMinimalConfigurationArgs = {
  offers: Array<Array<Scalars['String']['input']>>;
};


export type QueryListErc20Args = {
  filter?: InputMaybe<Erc20rFilter>;
  pagination: ConnectionArgs;
};


export type QueryOfferArgs = {
  _id: Scalars['String']['input'];
  mapTo?: TIdSource;
};


export type QueryOfferTypeArgs = {
  _id: Scalars['String']['input'];
  mapTo?: TIdSource;
};


export type QueryOffersArgs = {
  filter?: InputMaybe<OfferFilter>;
  pagination: ConnectionArgs;
};


export type QueryOrderArgs = {
  id: Scalars['String']['input'];
};


export type QueryOrdersArgs = {
  filter?: InputMaybe<OrdersFilter>;
  pagination: ConnectionArgs;
};


export type QueryOrdersProcessingStatisticArgs = {
  dateFrom: Scalars['Float']['input'];
  dateTo: Scalars['Float']['input'];
  offerTypes?: InputMaybe<Array<TOfferType>>;
  pointsCount?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryOrdersStatusesStatisticArgs = {
  dateFrom: Scalars['Float']['input'];
  dateTo: Scalars['Float']['input'];
  pointsCount?: InputMaybe<Scalars['Float']['input']>;
  status: Scalars['String']['input'];
};


export type QueryProviderArgs = {
  _id: Scalars['String']['input'];
};


export type QueryProvidersArgs = {
  filter: ProviderFilter;
  pagination: ConnectionArgs;
};


export type QueryTeeBalanceOfArgs = {
  address: Scalars['String']['input'];
};


export type QueryTeeOfferArgs = {
  _id: Scalars['String']['input'];
  mapTo?: TIdSource;
};


export type QueryTeeOffersArgs = {
  filter?: InputMaybe<TeeOfferFilter>;
  pagination: ConnectionArgs;
};


export type QueryUniqConsumersStatisticArgs = {
  dateFrom: Scalars['Float']['input'];
  dateTo: Scalars['Float']['input'];
  pointsCount?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryValidateConfiguraionArgs = {
  input: WorkflowConfigurationValidation;
};

export type ResourceRequirement = {
  __typename?: 'ResourceRequirement';
  provided: Scalars['Float']['output'];
  required: Scalars['Float']['output'];
};

export type SlotInfo = {
  __typename?: 'SlotInfo';
  cpuCores: Scalars['Float']['output'];
  diskUsage: Scalars['Float']['output'];
  gpuCores: Scalars['Float']['output'];
  ram: Scalars['Float']['output'];
  vram: Scalars['Float']['output'];
};

export type SlotInfoInput = {
  cpuCores: Scalars['Float']['input'];
  diskUsage: Scalars['Float']['input'];
  gpuCores: Scalars['Float']['input'];
  ram: Scalars['Float']['input'];
  vram: Scalars['Float']['input'];
};

export type SlotUsage = {
  __typename?: 'SlotUsage';
  maxTimeMinutes: Scalars['Float']['output'];
  minTimeMinutes: Scalars['Float']['output'];
  price: Scalars['String']['output'];
  priceType: PriceType;
};

export type SlotUsageInput = {
  maxTimeMinutes: Scalars['Float']['input'];
  minTimeMinutes: Scalars['Float']['input'];
  price: Scalars['String']['input'];
  priceType: PriceType;
};

export type Solution = {
  __typename?: 'Solution';
  mrEnclaves: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  offerId: Scalars['String']['output'];
};

export enum SortDir {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortParam = {
  /** sort field name */
  sortBy: Scalars['String']['input'];
  /** sort directory - ASC or DESC. Default value ASC */
  sortDir: SortDir;
};

export type StatisticPoint = {
  __typename?: 'StatisticPoint';
  timestamp: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type Stats = {
  __typename?: 'Stats';
  blocked?: Maybe<Scalars['Float']['output']>;
  canceled?: Maybe<Scalars['Float']['output']>;
  canceling?: Maybe<Scalars['Float']['output']>;
  done?: Maybe<Scalars['Float']['output']>;
  error?: Maybe<Scalars['Float']['output']>;
  freeCores?: Maybe<Scalars['Float']['output']>;
  new?: Maybe<Scalars['Float']['output']>;
  ordersInQueue?: Maybe<Scalars['Float']['output']>;
  processing?: Maybe<Scalars['Float']['output']>;
  suspended?: Maybe<Scalars['Float']['output']>;
};

export type StatsInput = {
  blocked?: InputMaybe<Scalars['Float']['input']>;
  canceled?: InputMaybe<Scalars['Float']['input']>;
  canceling?: InputMaybe<Scalars['Float']['input']>;
  done?: InputMaybe<Scalars['Float']['input']>;
  error?: InputMaybe<Scalars['Float']['input']>;
  freeCores?: InputMaybe<Scalars['Float']['input']>;
  new?: InputMaybe<Scalars['Float']['input']>;
  ordersInQueue?: InputMaybe<Scalars['Float']['input']>;
  processing?: InputMaybe<Scalars['Float']['input']>;
  suspended?: InputMaybe<Scalars['Float']['input']>;
};

export type SubOrderCreatedPayload = {
  __typename?: 'SubOrderCreatedPayload';
  parentOrderId: Scalars['String']['output'];
  subOrderId: Scalars['String']['output'];
};

export type SuborderCreatedInput = {
  parentOrderId: Scalars['String']['input'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** event - create or update an entity. My be filtered by consumer */
  event: SubscriptionPayload;
  /** order event */
  order: OrderPayload;
  /** order events updated event. My be filtered by order ID */
  orderEventsUpdated: OrderEventsUpdated;
  /** order status updated event. My be filtered by order ID */
  orderStatusUpdated: OrderStatusUpdatedPayload;
  /** Suborder created event. My be filtered by parent ID */
  subOrderCreated: SubOrderCreatedPayload;
  /** workflow created event. My be filtered by consumer and external ID */
  workflowCreated: WorkflowCreatedPayload;
};


export type SubscriptionEventArgs = {
  filter?: InputMaybe<EventFilter>;
};


export type SubscriptionOrderArgs = {
  consumer?: InputMaybe<Scalars['String']['input']>;
  eventTypes?: InputMaybe<Array<OrderEventType>>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  orderIds?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type SubscriptionOrderEventsUpdatedArgs = {
  input: OrderEventsUpdatedInput;
};


export type SubscriptionOrderStatusUpdatedArgs = {
  input: OrderStatusUpdatedInput;
};


export type SubscriptionSubOrderCreatedArgs = {
  input: SuborderCreatedInput;
};


export type SubscriptionWorkflowCreatedArgs = {
  input: WorkflowCreatedInput;
};

export type SubscriptionPayload = {
  __typename?: 'SubscriptionPayload';
  consumer?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Scalars['String']['output']>>;
  offerType?: Maybe<TOfferType>;
  subscriptionSource: SubscriptionSource;
  type: SubscriptionType;
};

export enum SubscriptionSource {
  Config = 'CONFIG',
  Erc20 = 'ERC20',
  Locking = 'LOCKING',
  Offer = 'OFFER',
  Order = 'ORDER',
  Provider = 'PROVIDER',
  Staking = 'STAKING',
  TeeOffer = 'TEE_OFFER',
  Transaction = 'TRANSACTION',
  Voting = 'VOTING'
}

export enum SubscriptionType {
  Add = 'add',
  Approve = 'approve',
  Default = 'default',
  Update = 'update'
}

/** Source of input id */
export enum TIdSource {
  Blockchain = 'Blockchain',
  Internal = 'Internal'
}

/** The supported offers type. */
export enum TOfferType {
  Data = 'Data',
  Solution = 'Solution',
  Storage = 'Storage',
  TeeOffer = 'TeeOffer'
}

export type TeeOffer = {
  __typename?: 'TeeOffer';
  /** system identifier */
  _id: Scalars['String']['output'];
  authority: Scalars['String']['output'];
  disabledAfter: Scalars['Float']['output'];
  enabled: Scalars['Boolean']['output'];
  /** blockchain id */
  id: Scalars['String']['output'];
  inactive?: Maybe<Scalars['Boolean']['output']>;
  options: Array<TeeOfferOption>;
  origins?: Maybe<Origins>;
  providerInfo: ProviderInformation;
  slots: Array<TeeOfferSlot>;
  stats?: Maybe<Stats>;
  tcb?: Maybe<TeeOfferTcb>;
  teeOfferInfo: TeeOfferInfo;
};

export type TeeOfferConnection = {
  __typename?: 'TeeOfferConnection';
  edges?: Maybe<Array<TeeOfferEdge>>;
  pageInfo?: Maybe<TeeOfferPageInfo>;
};

export type TeeOfferEdge = {
  __typename?: 'TeeOfferEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TeeOffer>;
};

export type TeeOfferFilter = {
  /** filter by teeOfferInfo.hardwareInfo.optionInfo → bandwidth */
  bandwidth?: InputMaybe<Scalars['Float']['input']>;
  /** filter by teeOfferInfo.hardwareInfo.slotInfo → cpuCores */
  cpuCores?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** filter by teeOfferInfo.hardwareInfo.slotInfo → diskUsage */
  diskUsage?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** filter by teeOfferInfo → enabled */
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** filter by teeOfferInfo.hardwareInfo.optionInfo → externalPort */
  externalPort?: InputMaybe<Scalars['Float']['input']>;
  /** filter by teeOfferInfo.hardwareInfo.slotInfo → gpuCores */
  gpuCores?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** filter by blockchain id */
  id?: InputMaybe<Scalars['String']['input']>;
  /** filter by TEE offer ids */
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter by inactive, "false" by default */
  inactive?: InputMaybe<Scalars['Boolean']['input']>;
  /** filter by teeOfferInfo → name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** filter by slot/option usage → pricePerHour */
  pricePerHour?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** filter by TEE offer providerInfo → actionAccount */
  providerActionAccounts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** filter by teeOfferInfo.hardwareInfo.slotInfo → ram */
  ram?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** filter by teeOfferInfo.hardwareInfo.optionInfo → traffic */
  traffic?: InputMaybe<Scalars['Float']['input']>;
  /** filter by slot/option usage → minTimeMinutes,maxTimeMinutes */
  usageMinutes?: InputMaybe<Scalars['Float']['input']>;
  /** filter by teeOfferInfo.hardwareInfo.slotInfo → vram */
  vram?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type TeeOfferInfo = {
  __typename?: 'TeeOfferInfo';
  argsPublicKey: Scalars['String']['output'];
  description: Scalars['String']['output'];
  hardwareInfo: HardwareInfo;
  name: Scalars['String']['output'];
  properties: Scalars['String']['output'];
  subType: Scalars['String']['output'];
  teeType: Scalars['String']['output'];
};

export type TeeOfferInfoInput = {
  argsPublicKey: Scalars['String']['input'];
  description: Scalars['String']['input'];
  hardwareInfo: HardwareInfoInput;
  name: Scalars['String']['input'];
  properties: Scalars['String']['input'];
  subType: Scalars['String']['input'];
  teeType: Scalars['String']['input'];
};

export type TeeOfferInputType = {
  authority: Scalars['String']['input'];
  disabledAfter: Scalars['Float']['input'];
  enabled: Scalars['Boolean']['input'];
  inactive?: InputMaybe<Scalars['Boolean']['input']>;
  options: Array<TeeOfferOptionInput>;
  providerInfo: ProviderInformationInput;
  slots: Array<TeeOfferSlotInput>;
  stats?: InputMaybe<StatsInput>;
  tcb?: InputMaybe<TeeOfferTcbInputType>;
  teeOfferInfo: TeeOfferInfoInput;
};

export type TeeOfferOption = {
  __typename?: 'TeeOfferOption';
  id: Scalars['String']['output'];
  info: OptionInfo;
  usage: SlotUsage;
};

export type TeeOfferOptionInput = {
  id: Scalars['String']['input'];
  info: OptionInfoInput;
  usage: SlotUsageInput;
};

export type TeeOfferPageInfo = {
  __typename?: 'TeeOfferPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type TeeOfferSlot = {
  __typename?: 'TeeOfferSlot';
  id: Scalars['String']['output'];
  info: SlotInfo;
  usage: SlotUsage;
};

export type TeeOfferSlotInput = {
  id: Scalars['String']['input'];
  info: SlotInfoInput;
  usage: SlotUsageInput;
};

export type TeeOfferTcb = {
  __typename?: 'TeeOfferTcb';
  checkedAt?: Maybe<Scalars['Float']['output']>;
  id: Scalars['String']['output'];
};

export type TeeOfferTcbInputType = {
  checkedAt?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['String']['input'];
};

export type TeeOfferWithSlotsAndOptions = {
  offerId: Scalars['String']['input'];
  options: Array<TeeSlot>;
  slot: TeeSlot;
};

export type TeeOffersAndSLots = {
  __typename?: 'TeeOffersAndSLots';
  optionsResult?: Maybe<MatchingOptions>;
  price: Scalars['String']['output'];
  slotResult: MatchingSlot;
  teeOffer: TeeOffer;
};

export type TeeOffersAndSLotsConnection = {
  __typename?: 'TeeOffersAndSLotsConnection';
  edges?: Maybe<Array<TeeOffersAndSLotsEdge>>;
  pageInfo?: Maybe<TeeOffersAndSLotsPageInfo>;
};

export type TeeOffersAndSLotsEdge = {
  __typename?: 'TeeOffersAndSLotsEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TeeOffersAndSLots>;
};

export type TeeOffersAndSLotsPageInfo = {
  __typename?: 'TeeOffersAndSLotsPageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type TeeOffersAndSLotsType = {
  optionsResult?: InputMaybe<MatchingOptionsType>;
  price: Scalars['String']['input'];
  slotResult: MatchingSlotType;
  teeOffer: TeeOfferInputType;
};

export type TeeSlot = {
  count: Scalars['Float']['input'];
  id: Scalars['String']['input'];
};

export type ValidationErrors = {
  __typename?: 'ValidationErrors';
  bandwidth?: Maybe<ResourceRequirement>;
  cpuCores?: Maybe<ResourceRequirement>;
  diskUsage?: Maybe<ResourceRequirement>;
  externalPort?: Maybe<ResourceRequirement>;
  gpuCores?: Maybe<ResourceRequirement>;
  minTimeMinutes?: Maybe<ResourceRequirement>;
  ram?: Maybe<ResourceRequirement>;
  traffic?: Maybe<ResourceRequirement>;
  vram?: Maybe<ResourceRequirement>;
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  errors?: Maybe<ValidationErrors>;
  success: Scalars['Boolean']['output'];
};

export type ValueObject = {
  __typename?: 'ValueObject';
  actionAccountAddress?: Maybe<Scalars['String']['output']>;
  authorityAccountAddress?: Maybe<Scalars['String']['output']>;
  offerSecDeposit?: Maybe<Scalars['String']['output']>;
  orderMinimumDeposit?: Maybe<Scalars['String']['output']>;
  staking?: Maybe<Scalars['String']['output']>;
  superpro?: Maybe<Scalars['String']['output']>;
  teeOfferSecDeposit?: Maybe<Scalars['String']['output']>;
  teeRewardPerEpoch?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  tokenReceiverAddress?: Maybe<Scalars['String']['output']>;
};

export type ValueObjectType = {
  actionAccountAddress?: InputMaybe<Scalars['String']['input']>;
  authorityAccountAddress?: InputMaybe<Scalars['String']['input']>;
  offerSecDeposit?: InputMaybe<Scalars['String']['input']>;
  orderMinimumDeposit?: InputMaybe<Scalars['String']['input']>;
  staking?: InputMaybe<Scalars['String']['input']>;
  superpro?: InputMaybe<Scalars['String']['input']>;
  teeOfferSecDeposit?: InputMaybe<Scalars['String']['input']>;
  teeRewardPerEpoch?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  tokenReceiverAddress?: InputMaybe<Scalars['String']['input']>;
};

export type ValueOfferWithSlotsAndOptions = {
  offerId: Scalars['String']['input'];
  slot: ValueSlot;
};

export type ValueSlot = {
  id: Scalars['String']['input'];
};

export type WorkflowConfigurationValidation = {
  data: Array<ValueOfferWithSlotsAndOptions>;
  minTimeMinutes?: InputMaybe<Scalars['Float']['input']>;
  solution?: InputMaybe<Array<ValueOfferWithSlotsAndOptions>>;
  storage: ValueOfferWithSlotsAndOptions;
  tee: TeeOfferWithSlotsAndOptions;
};

export type WorkflowCreatedFilter = {
  consumer: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
};

export type WorkflowCreatedInput = {
  filter: WorkflowCreatedFilter;
};

export type WorkflowCreatedPayload = {
  __typename?: 'WorkflowCreatedPayload';
  consumer: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  offerId: Scalars['String']['output'];
  orderId: Scalars['String']['output'];
};
